/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { archiveCourse } from './archiveCourse'
import { createCourse } from './createCourse'
import { enrollStudentInCourse } from './enrollStudentInCourse'
import { ensureGoogleClassroomCourseForTeacher } from './ensureGoogleClassroomCourseForTeacher'
import { ensureStudentCourseEnrollment } from './ensureStudentCourseEnrollment'
import { getAllAssignableCoursesForInstructor } from './getAllAssignableCoursesForInstructor'
import { getAllCoursesForOrganization } from './getAllCoursesForOrganization'
import { getCourse } from './getCourse'
import { getCourseRoster } from './getCourseRoster'
import { getCourses } from './getCourses'
import { getCoursesByClassroomCourseId } from './getCoursesByClassroomCourseId'
import { saveCourse } from './saveCourse'
import { updateCourse } from './updateCourse'
import { updateCourseName } from './updateCourseName'
import { updateCourseRoles } from './updateCourseRoles'
import { updateCourseRoles2 } from './updateCourseRoles2'
import { updatePreferredCitationFormat } from './updatePreferredCitationFormat'
import { upgradeCourseToUseSections } from './upgradeCourseToUseSections'

const CourseService = {
  archiveCourse,
  createCourse,
  enrollStudentInCourse,
  ensureGoogleClassroomCourseForTeacher,
  ensureStudentCourseEnrollment,
  getAllAssignableCoursesForInstructor,
  getAllCoursesForOrganization,
  getCourse,
  getCourseRoster,
  getCourses,
  getCoursesByClassroomCourseId,
  saveCourse,
  updateCourse,
  updateCourseName,
  updateCourseRoles,
  updateCourseRoles2,
  updatePreferredCitationFormat,
  upgradeCourseToUseSections,
}

export default CourseService

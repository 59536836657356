import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'
import { getCourse } from './getCourse'

export async function ensureGoogleClassroomCourseForTeacher(
  uid,
  courseId,
  classroomCourseName,
) {
  try {
    // attempt to load the course, throw errors if it doesn't exist or the user is not a teacher
    let course
    try {
      course = await getCourse(courseId)
    } catch (err) {
      console.error('getCourse failed:', err)
      throw new Error(
        'Course appears to exist, but the user does not have access to it.',
      )
    }
    console.log('getCourse successful:', course)
    if (!course) {
      throw new Error('Course does not exist')
    }
    if (!course.roles.Instructor?.includes(uid)) {
      throw new Error('User is not a teacher of the course')
    }
  } catch (err) {
    const addToCourseResult = await httpsCallable(
      functions,
      'ensureGoogleClassroomCourseForTeacher',
    )({
      uid,
      courseId,
      name: classroomCourseName,
    })
    if (!addToCourseResult.data.success) {
      throw new Error(addToCourseResult.data.error)
    }
    return addToCourseResult.data.orgId
  }
}

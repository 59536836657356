import { paths } from '@learnics/models/src/utils/paths'

import { EventMetaInfo } from '@learnics/models/src/Organization/Course/Assignment/Submission/EventMetaInfo'
import { Entry } from '@learnics/models/src/Organization/Course/Assignment/Submission/Entry'
import { LoggingSessionChunk } from '@learnics/models/src/Organization/Course/Assignment/Submission/LoggingSessionChunk'
import { Site } from '@learnics/models/src/Organization/Course/Assignment/Submission/Site'
import { nanoid } from 'nanoid'
import { Invitation } from '@learnics/models/src/Invitation/Invitation'
import {
  AssignmentDetailFirestoreConverter,
} from '@learnics/models/src/utils/converters/AssignmentDetailFirestoreConverter'
import { AssignmentFirestoreConverter } from '@learnics/models/src/utils/converters/AssignmentFirestoreConverter'
import {
  BasicAccessListFirestoreConverter,
} from '@learnics/models/src/utils/converters/BasicAccessListFirestoreConverter'
import { BucketFileFirestoreConverter } from '@learnics/models/src/utils/converters/BucketFileFirestoreConverter'
import { CourseFirestoreConverter } from '@learnics/models/src/utils/converters/CourseFirestoreConverter'
import {
  getBasicClassBasedFirestoreConverter,
} from '@learnics/models/src/utils/converters/getBasicClassBasedFirestoreConverter'
import { LoggerSessionFirestoreConverter } from '@learnics/models/src/utils/converters/LoggerSessionFirestoreConverter'
import { OrganizationFirestoreConverter } from '@learnics/models/src/utils/converters/OrganizationFirestoreConverter'
import { SectionFirestoreConverter } from '@learnics/models/src/utils/converters/SectionFirestoreConverter'
import { UserDataFirestoreConverter } from '@learnics/models/src/utils/converters/UserDataFirestoreConverter'
import { UserSiteDataFirestoreConverter } from '@learnics/models/src/utils/converters/UserSiteDataFirestoreConverter'
import { ExtensionInstallation } from '@learnics/models/src/ExtensionInstallation/ExtensionInstallation'
import { GoogleDriveFile } from '@learnics/models/src/GoogleDrive/GoogleDriveFile'
import { NoteBoard } from '@learnics/models/src/NoteBoard/NoteBoard'
import { NoteBoardUser } from '@learnics/models/src/NoteBoard/NoteBoardUser'
import { ResourceDriveFolder } from '@learnics/models/src/GoogleDrive/ResourceDriveFolder'
import { StudentCopyDriveFile } from '@learnics/models/src/GoogleDrive/StudentCopyDriveFile'
import { AssignmentSessionLink } from '@learnics/models/src/AssignmentSessionLink/AssignmentSessionLink'
import { Note } from '@learnics/models/src/Note/Note'
import { createYDocFirestoreConverter } from './utils/converters/createYDocFirestoreConverter'
import { ResearchNotebook } from '@learnics/models/src/ResearchNotebook/ResearchNotebook'
import { AccessRequest } from '@learnics/models/src/AccessRequest/AccessRequest'
import ProductTrial from './productTrial'

// create the refs object
export function getRefs(db, doc, collection, Timestamp) {
  return {
    // These mark the access control list mirrored collections/documents
    access: () => collection(db, paths.access()),
    accessControl: () => doc(db, paths.accessControl()),
    acl: (path, useConverter = true) => doc(db, paths.acl(path)).withConverter(useConverter ? BasicAccessListFirestoreConverter : null),
    acls: (path, useConverter = true) => collection(db, paths.acl(path)).withConverter(useConverter ? BasicAccessListFirestoreConverter : null),

    accessCode: (accessCode) => doc(db, paths.accessCode(accessCode)),
    accessCodes: () => collection(db, paths.accessCodes()),
    accessRequest: (accessRequestId = nanoid(), useConverter = true) => doc(db, paths.accessRequest(accessRequestId)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(AccessRequest) : null),
    accessRequests: (useConverter = true) => collection(db, paths.accessRequests()).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(AccessRequest) : null),
    assignment: (assignmentId = nanoid(), useConverter = true) => doc(db, paths.assignment(assignmentId)).withConverter(useConverter ? AssignmentFirestoreConverter : null),
    assignmentSessionId: (assignmentId, userId = nanoid()) => doc(db, paths.assignmentSessionId(assignmentId, userId)),
    assignmentSessionIds: (assignmentId) => collection(db, paths.assignmentSessionIds(assignmentId)),
    assignmentSessionLink: (sessionId = nanoid(), useConverter = true) => doc(db, paths.assignmentSessionLink(sessionId)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(AssignmentSessionLink) : null),
    assignmentSessionLinks: (useConverter = true) => collection(db, paths.assignmentSessionLinks()).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(AssignmentSessionLink) : null),
    /** @deprecated prefer assignmentSessionId */
    assignmentUserKey: (assignmentId, userId = nanoid()) => doc(db, paths.assignmentUserKey(assignmentId, userId)),
    /** @deprecated prefer assignmentSessionIds */
    assignmentUserKeys: (assignmentId) => collection(db, paths.assignmentUserKeys(assignmentId)),
    assignmentDetail: (assignmentDetailId = nanoid(), useConverter = true) => doc(db, paths.assignmentDetail(assignmentDetailId)).withConverter(useConverter ? AssignmentDetailFirestoreConverter : null),
    assignmentDetails: (useConverter = true) => collection(db, paths.assignmentDetails()).withConverter(useConverter ? AssignmentDetailFirestoreConverter : null),
    assignments: (useConverter = true) => collection(db, paths.assignments()).withConverter(useConverter ? AssignmentFirestoreConverter : null),
    bucketFile: (fileId = nanoid(), useConverter = true) => doc(db, paths.bucketFile(fileId)).withConverter(useConverter ? BucketFileFirestoreConverter : null),
    bucketFiles: (useConverter = true) => collection(db, paths.bucketFiles()).withConverter(useConverter ? BucketFileFirestoreConverter : null),
    collaborativeInstances: (path) => collection(db, paths.collaborativeInstances(path)),
    collaborativeInstance: (path, instanceId) => doc(db, paths.collaborativeInstance(path, instanceId)),
    collaborativeInstanceCalls: (path, instanceId) => collection(db, paths.collaborativeInstanceCalls(path, instanceId)),
    collaborativeInstanceCall: (path, instanceId, callId) => doc(db, paths.collaborativeInstanceCall(path, instanceId, callId)),
    collaborativeInstanceAnswers: (path, instanceId) => collection(db, paths.collaborativeInstanceAnswers(path, instanceId)),
    collaborativeInstanceAnswer: (path, instanceId, answerId) => doc(db, paths.collaborativeInstanceAnswer(path, instanceId, answerId)),
    course: (courseId = nanoid(), useConverter = true) => doc(db, paths.course(courseId)).withConverter(useConverter ? CourseFirestoreConverter : null),
    courses: (useConverter = true) => collection(db, paths.courses()).withConverter(useConverter ? CourseFirestoreConverter : null),
    domains: () => collection(db, paths.domains()),
    domain: (domainId = nanoid()) => doc(db, paths.domain(domainId)),

    /** @deprecated */
    entries: (assignmentId, userId, useConverter = true) => collection(db, paths.entries(assignmentId, userId)).withConverter(useConverter ? Entry.firestoreConverter(Timestamp.fromDate) : null),
    /** @deprecated */
    entry: (assignmentId, userId, entryId = nanoid(), useConverter = true) => doc(db, paths.entry(assignmentId, userId, entryId)).withConverter(useConverter ? Entry.firestoreConverter(Timestamp.fromDate) : null),
    error: (errorId = nanoid()) => doc(db, paths.error(errorId)),
    errors: () => collection(db, paths.errors()),
    eventLog: (sessionId, chunkId = nanoid(), useConverter = true) => doc(db, paths.eventLog(sessionId, chunkId)).withConverter(useConverter ? LoggingSessionChunk : null),
    eventLogs: (sessionId, useConverter = true) => collection(db, paths.eventLogs(sessionId)).withConverter(useConverter ? LoggingSessionChunk : null),
    eventLogMetaInfo: (sessionId, useConverter = true) => doc(db, paths.eventLogMetaInfo(sessionId)).withConverter(useConverter ? EventMetaInfo : null),
    extensionInstallations: (useConverter = true) => collection(db, paths.extensionInstallations()).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(ExtensionInstallation) : null),
    extensionInstallation: (installationId = nanoid(), useConverter = true) => doc(db, paths.extensionInstallation(installationId)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(ExtensionInstallation): null),
    gapiOauth2Tokens: () => collection(db, paths.gapiOauth2Tokens()),
    gapiOauth2Token: (userId) => doc(db, paths.gapiOauth2Token(userId)),
    googleDriveFile: (fileId = nanoid(), useConverter = true) => doc(db, paths.googleDriveFile(fileId)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(GoogleDriveFile) : null),
    googleDriveFiles: (useConverter = true) => collection(db, paths.googleDriveFiles()).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(GoogleDriveFile) : null),
    invitation: (invitationId = nanoid(), useConverter = true) => doc(db, paths.invitation(invitationId)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(Invitation) : null),
    invitations: () => collection(db, paths.invitations()),
    keysDeleted: (sessionId, chunkId = nanoid()) => doc(db, paths.keysDeleted(sessionId, chunkId)),
    keysDeleteds: (sessionId) => collection(db, paths.keysDeleteds(sessionId)),
    loggerSession: (sessionId = nanoid()) => doc(db, paths.loggerSession(sessionId)).withConverter(LoggerSessionFirestoreConverter),
    loggerSessions: () => collection(db, paths.loggerSessions()).withConverter(LoggerSessionFirestoreConverter),
    note: (noteId = nanoid(), useConverter = true) => doc(db, paths.note(noteId)).withConverter(useConverter ? createYDocFirestoreConverter(Note) : null),
    notes: (useConverter = true) => collection(db, paths.notes()).withConverter(useConverter ? createYDocFirestoreConverter(Note) : null),
    noteMeshes: (noteId) => collection(db, paths.noteMeshes(noteId)),
    noteMesh: (noteId, meshId) => doc(db, paths.noteMesh(noteId, meshId)),
    noteDebug: (noteId = nanoid()) => doc(db, paths.noteDebug(noteId)),
    noteBoard: (noteBoardId = nanoid(), useConverter = true) => doc(db, paths.noteBoard(noteBoardId)).withConverter(useConverter ? createYDocFirestoreConverter(NoteBoard) : null),
    noteBoards: (useConverter = true) => collection(db, paths.noteBoards()).withConverter(useConverter ? createYDocFirestoreConverter(NoteBoard) : null),
    noteBoardMeshes: (noteBoardId) => collection(db, paths.noteBoardMeshes(noteBoardId)),
    noteBoardMesh: (noteBoardId, meshId) => doc(db, paths.noteBoardMesh(noteBoardId, meshId)),
    noteBoardDebug: (noteBoardId) => doc(db, paths.noteBoardDebug(noteBoardId)),
    noteBoardUsers: (noteBoardId, useConverter = true) => collection(db, paths.noteBoardUsers(noteBoardId)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(NoteBoardUser) : null),
    noteBoardUser: (noteBoardId, userId, useConverter = true) => doc(db, paths.noteBoardUser(noteBoardId, userId)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(NoteBoardUser) : null),

    /** @deprecated */
    oldKeysDeleteds: (assignmentId, sessionId) => collection(db, paths.assignment(assignmentId) +"/eventLog/" + sessionId + "/keysDeleted"),
    /** @deprecated */
    oldSecrets: (assignmentId, sessionId) => collection(db, paths.assignment(assignmentId) +"/eventLog/" + sessionId + "/secrets"),
    /** @deprecated */
    oldEvents: (assignmentId, sessionId) => collection(db, paths.assignment(assignmentId) +"/eventLog/" + sessionId + "/events"),
    /** @deprecated */
    oldEventLogMetainfo: (assignmentId, sessionId) => doc(db, paths.assignment(assignmentId) +"/eventLog/" + sessionId + "/events/meta"),

    organization: (orgId = nanoid(), useConverter = true) => doc(db, paths.organization(orgId)).withConverter(useConverter ? OrganizationFirestoreConverter : null),
    organizations: (useConverter = true) => collection(db, paths.organizations()).withConverter(useConverter ? OrganizationFirestoreConverter : null),
    productTrial: (productTrialId = nanoid(), useConverter = true) => doc(db, paths.productTrial(productTrialId)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(ProductTrial) : null),
    productTrials: (useConverter = true) => collection(db, paths.productTrials()).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(ProductTrial) : null),
    researchNotebook: (researchNotebookId, useConverter = true) => doc(db, paths.researchNotebook(researchNotebookId)).withConverter(useConverter ? createYDocFirestoreConverter(ResearchNotebook) : null),
    researchNotebooks: (useConverter = true) => collection(db, paths.researchNotebooks()).withConverter(useConverter ? createYDocFirestoreConverter(ResearchNotebook) : null),
    researchNotebookMeshes: (researchNotebookId) => collection(db, paths.researchNotebookMeshes(researchNotebookId)),
    researchNotebookMesh: (researchNotebookId, meshId) => doc(db, paths.researchNotebookMesh(researchNotebookId, meshId)),
    researchNotebookDebug: (researchNotebookId = nanoid()) => doc(db, paths.researchNotebookDebug(researchNotebookId)),
    resourceDriveFolder: (id = nanoid(), useConverter = true) =>  doc(db, paths.resourceDriveFolder(id)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(ResourceDriveFolder) : null),
    resourceDriveFolders: (useConverter = true) => collection(db, paths.resourceDriveFolders()).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(ResourceDriveFolder) : null),
    secret: (sessionId, chunkId = nanoid()) => doc(db, paths.secret(sessionId, chunkId)),
    secrets: (sessionId) => collection(db, paths.secrets(sessionId)),
    section: (sectionId = nanoid(), useConverter = true) => doc(db, paths.section(sectionId)).withConverter(useConverter ? SectionFirestoreConverter : null),
    sections: (useConverter = true) => collection(db, paths.sections()).withConverter(useConverter ? SectionFirestoreConverter : null),
    site: (assignmentId, userId, entryId, siteId = nanoid(), useConverter = true) => doc(db, paths.site(assignmentId, userId, entryId, siteId)).withConverter(useConverter ? Site : null),
    sites: (assignmentId, userId, entryId, useConverter = true) => collection(db, paths.sites(assignmentId, userId, entryId)).withConverter(useConverter ? Site : null),
    studentCopyDriveFile: (id = nanoid(), useConverter = true) => doc(db, paths.studentCopyDriveFile(id)).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(StudentCopyDriveFile) : null),
    studentCopyDriveFiles: (useConverter = true) => collection(db, paths.studentCopyDriveFiles()).withConverter(useConverter ? getBasicClassBasedFirestoreConverter(StudentCopyDriveFile) : null),
    submission: (assignmentId, userId = nanoid()) => doc(db, paths.submission(assignmentId, userId)),
    submissions: (assignmentId) => collection(db, paths.submissions(assignmentId)),
    user: (userId = nanoid()) => doc(db, paths.user(userId)),
    users: () => collection(db, paths.users()),
    userData: (userId, useConverter = true) => doc(db, paths.userData(userId)).withConverter(useConverter ? UserDataFirestoreConverter : null),
    userDatas: (useConverter = true) => collection(db, paths.userDatas()).withConverter(useConverter ? UserDataFirestoreConverter : null),
    usersIndex: () => doc(db, paths.usersIndex()),
    usersIndexChunks: () => collection(db, paths.usersIndexChunks()),
    usersIndexChunk: (chunkId) => doc(db, paths.usersIndexChunk(chunkId)),
    userSiteDatas: (sessionId, useConverter = true) => collection(db, paths.userSiteDatas(sessionId)).withConverter(useConverter ? UserSiteDataFirestoreConverter : null),
    userSiteData: (sessionId, urlHash, useConverter = true) => doc(db, paths.userSiteData(sessionId, urlHash)).withConverter(useConverter ? UserSiteDataFirestoreConverter : null),
    workCited: (assignmentId, userId) => doc(db, paths.workCited(assignmentId, userId)),
    workCitedForSession: (sessionId) => doc(db, paths.workCitedForSession(sessionId)),
    workCitedFormatSettings: (assignmentId, userId) => doc(db, paths.workCitedFormatSettings(assignmentId, userId)),
    workCitedFormatSettingsForSession: (sessionId) => doc(db, paths.workCitedFormatSettingsForSession(sessionId)),
  }
}

/**
 * paths.js is a file to store all the paths to the database.  This is to make it easier to change the database structure in the future.
 *
 * Although there is still more to it than simply changing the path here, it is a very good start.  If something
 * grabs an Assignment object from the database, for example, it will ALWAYS go through this file to get the path.
 *
 * Each of the functions in this file returns a string that is the path to the database.  The functions are named
 * to match the database structure.  For example, the function `paths.assignment` returns the path to an assignment
 * document.  The function `paths.assignments` returns the path to the assignments collection.
 */
export const paths = {
  // These mark the access control list mirrored collections/documents
  access: () => `access`,
  accessControl: (resourceType) => `${paths.access()}/control`,
  acl: (path) => `${paths.accessControl()}/${path}`,
  accessCodes: () => 'access_codes',
  accessCode: (accessCode) => `${paths.accessCodes()}/${accessCode}`,
  accessRequests: () => 'access_requests',
  accessRequest: (accessRequestId) => `${paths.accessRequests()}/${accessRequestId}`,
  assignment: (assignmentId) => `${paths.assignments()}/${assignmentId}`,
  /** @deprecated  prefer assignmentSessionIds */
  assignmentUserKeys: (assignmentId) => paths.assignmentSessionIds(assignmentId),
  /** @deprecated  prefer assignmentSessionId */
  assignmentUserKey: (assignmentId, userId) => paths.assignmentSessionId(assignmentId, userId),
  assignmentSessionIds: (assignmentId) => `${paths.assignment(assignmentId)}/keys`,
  assignmentSessionId: (assignmentId, userId) => `${paths.assignmentSessionIds(assignmentId)}/${userId}`,
  assignmentSessionLinks: () => `assignment_session_links`,
  assignmentSessionLink: (sessionId) => `${paths.assignmentSessionLinks()}/${sessionId}`,
  assignmentDetail: (assignmentDetailId) => `${paths.assignmentDetails()}/${assignmentDetailId}`,
  assignmentDetails: () => `flat_assignment_details`,
  assignments: () => `flat_assignments`,
  bucketFile: (fileId) => `${paths.bucketFiles()}/${fileId}`,
  bucketFiles: () => `bucket_files`,
  collaborativeInstances: (path)=> `${path}/instances`,
  collaborativeInstance: (path, instanceId)=> `${paths.collaborativeInstances(path)}/${instanceId}`,
  collaborativeInstanceCalls: (path, instanceId)=> `${paths.collaborativeInstance(path, instanceId)}/calls`,
  collaborativeInstanceCall: (path, instanceId, callId) => `${paths.collaborativeInstanceCalls(path, instanceId)}/${callId}`,
  collaborativeInstanceAnswers: (path, instanceId) => `${paths.collaborativeInstance(path, instanceId)}/answers`,
  collaborativeInstanceAnswer: (path, instanceId, answerId) => `${paths.collaborativeInstanceAnswers(path, instanceId)}/${answerId}`,
  course:(courseId) => `${paths.courses()}/${courseId}`,
  courses:() => `flat_courses`,
  domain: (domainId) => `${paths.domains()}/${domainId}`,
  domains: () => `domains`,
  entries:(assignmentId, userId) => `${paths.submission(assignmentId, userId)}/entries`,
  entry:(assignmentId, userId, entryId) => `${paths.entries(assignmentId, userId)}/${entryId}`,
  error: (errorId) => `${paths.errors()}/${errorId}`,
  errors: () => 'errors',
  eventLog:(sessionId, chunkId) => `${paths.eventLogs(sessionId)}/${chunkId}`,
  eventLogs:(sessionId) => `${paths.loggerSession(sessionId)}/events`,
  eventLogMetaInfo:(sessionId) => paths.eventLog(sessionId, 'meta'),
  extensionInstallations: () => 'extension_installations',
  extensionInstallation: (installationId) => `${paths.extensionInstallations()}/${installationId}`,
  gapiOauth2Token: (userId) => `${paths.gapiOauth2Tokens()}/${userId}`,
  gapiOauth2Tokens: () => 'gapi_oauth2_tokens',
  googleDriveFile: (driveFileId) => `${paths.googleDriveFiles()}/${driveFileId}`,
  googleDriveFiles: () => 'google_drive_files',
  invitation: (invitationId) => `${paths.invitations()}/${invitationId}`,
  invitations: () => 'invitations',
  keysDeleted:(sessionId, chunkId) => `${paths.keysDeleteds(sessionId)}/${chunkId}`,
  keysDeleteds:(sessionId) => `${paths.loggerSession(sessionId)}/keysDeleted`,
  loggerSession:(sessionId) => `${paths.loggerSessions()}/${sessionId}`,
  loggerSessions:() => `logger_sessions`,
  note: (noteId) => `${paths.notes()}/${noteId}`,
  notes: () => `notes`,
  noteMeshes: (noteId) => `${paths.note(noteId)}/instances`,
  noteMesh: (noteId, meshId) => `${paths.noteMeshes(noteId)}/${meshId}`,
  noteDebug: (noteId) => `${paths.note(noteId)}/debug/data`,
  noteBoard: (noteBoardId) => `${paths.noteBoards()}/${noteBoardId}`,
  noteBoards: () => `note_boards`,
  noteBoardMeshes: (noteBoardId) => `${paths.noteBoard(noteBoardId)}/instances`,
  noteBoardMesh: (noteBoardId, meshId) => `${paths.noteBoardMeshes(noteBoardId)}/${meshId}`,
  noteBoardUsers: (noteBoardId, userId) => `${paths.noteBoard(noteBoardId)}/users`,
  noteBoardUser: (noteBoardId, userId) => `${paths.noteBoardUsers(noteBoardId)}/${userId}`,
  noteBoardDebug: (noteBoardId) => `${paths.noteBoard(noteBoardId)}/debug/data`,
  organization: (orgId) => `${paths.organizations()}/${orgId}`,
  organizations: () => 'flat_organizations',
  productTrial: (trialId) => `${paths.productTrials()}/${trialId}`,
  productTrials: () => 'product_trials',
  resourceDriveFolder: (id) => `${paths.resourceDriveFolders()}/${id}`,
  resourceDriveFolders: () => 'resource_drive_folders',
  researchNotebook: (researchNotebookId) => `${paths.researchNotebooks()}/${researchNotebookId}`,
  researchNotebooks: () => `research_notebooks`,
  researchNotebookMeshes: (researchNotebookId) => `${paths.researchNotebook(researchNotebookId)}/instances`,
  researchNotebookMesh: (researchNotebookId, meshId) => `${paths.researchNotebookMeshes(researchNotebookId)}/${meshId}`,
  researchNotebookDebug: (researchNotebookId) => `${paths.researchNotebook(researchNotebookId)}/debug/data`,
  secret:(sessionId, chunkId) => `${paths.secrets(sessionId)}/${chunkId}`,
  secrets:(sessionId) => `${paths.loggerSession(sessionId)}/secrets`,
  section:(sectionId) => `${paths.sections()}/${sectionId}`,
  sections:() => `sections`,
  studentCopyDriveFile: (id) => `${paths.studentCopyDriveFiles()}/${id}`,
  studentCopyDriveFiles: () => 'student_copy_drive_files',
  site:(assignmentId, userId, entryId, siteId) => `${paths.sites(assignmentId, userId, entryId)}/${siteId}`,
  sites:(assignmentId, userId, entryId) => `${paths.entry(assignmentId, userId, entryId)}/sites`,
  submission:(assignmentId, userId) => `${paths.submissions(assignmentId)}/${userId}`,
  submissions:(assignmentId) => `${paths.assignment(assignmentId)}/submissions`,
  user:(userId) => `${paths.users()}/${userId}`,
  users: () => 'users',
  userData: (userId) => `user_data/${userId}`,
  userDatas: () => 'user_data',
  usersIndex:  () => 'index/users',
  usersIndexChunks: () => `${paths.usersIndex()}/chunks`,
  usersIndexChunk: (chunkId) => `${paths.usersIndexChunks()}/${chunkId}`,
  userSiteDatas: (sessionId) => `${paths.loggerSession(sessionId)}/urls`,
  userSiteData: (sessionId, urlHash) => `${paths.userSiteDatas(sessionId)}/${urlHash}`,
  workCited:(assignmentId, userId) => `${paths.assignment(assignmentId)}/student/${userId}/bibliography/citation`,
  workCitedForSession:(sessionId) => `${paths.loggerSession(sessionId)}/bibliography/citation`,
  workCitedFormatSettings:(assignmentId, userId) => `${paths.assignment(assignmentId)}/student/${userId}/bibliography/formatSettings`,
  workCitedFormatSettingsForSession:(sessionId) => `${paths.loggerSession(sessionId)}/bibliography/formatSettings`,
}

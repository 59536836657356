import { nanoid } from 'nanoid'

export class AccessRequest {
  constructor({
    id = nanoid(32),
    resourceType,
    resourceId,
    userId,
    roles = ['Viewer'],
    responseDate = null,
    accepted = false,
  }) {
    this.id = id
    this.resourceType = resourceType
    this.resourceId = resourceId
    this.userId = userId
    this.roles = roles
    this.responseDate = responseDate || null
    this.accepted = !!accepted
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {Object}
   */
  toJson() {
    return {
      id: this.id,
      resourceType: this.resourceType,
      resourceId: this.resourceId,
      userId: this.userId,
      roles: [...this.roles],
      responseDate: this.responseDate || null,
      accepted: !!this.accepted,
    }
  }
}

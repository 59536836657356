import { Tag } from '../Tag/Tag'
import { TagArray } from '../Tag/TagArray'

/**
 * ResearchSource is an abstract class that holds note and annotation
 * information about a particular source of research, for an individual user's
 * research notebook.
 *
 * @abstract
 */
export class ResearchSource {
  yMap
  constructor(yMap) {
    if (!yMap) {
      throw new Error('yMap is required')
    }
    this.yMap = yMap
    const annotationNoteId = this.yMap.get('annotationNoteId')
    const noteIdsArray = this.yMap.get('noteIds')
    if (
      annotationNoteId &&
      noteIdsArray.toJSON().indexOf(annotationNoteId) === -1
    ) {
      // Fix broken annotationNoteId links
      this.yMap.set('annotationNoteId', null)
    }
  }

  get tagArray() {
    return new TagArray(this.yMap.get('tags'))
  }

  get type() {
    return this.yMap.get('type')
  }

  get id() {
    return this.yMap.get('id')
  }

  get tags() {
    const result = []
    this.yMap.get('tags').forEach((tagMap) => {
      result.push(new Tag(tagMap))
    })
    return result
  }

  get noteIds() {
    return this.yMap.get('noteIds').toJSON()
  }

  get annotationNoteId() {
    return this.yMap.get('annotationNoteId')
  }

  set annotationNoteId(noteId) {
    this.yMap.set('annotationNoteId', noteId)
  }

  applyDeltaToNoteIds(delta) {
    for (const op of delta) {
      if (op.type === 'insert') {
        this.yMap.get('noteIds').insert(op.index, [op.element])
      } else if (op.type === 'delete') {
        this.yMap.get('noteIds').delete(op.index, 1)
      }
    }
  }

  toggleAnnotationNoteId(noteId) {
    if (this.annotationNoteId === noteId) {
      this.yMap.set('annotationNoteId', null)
    } else {
      this.yMap.set('annotationNoteId', noteId)
    }
  }

  replaceTag(index, newColor, newLabel) {
    return this.tagArray.swapTag(index, newColor, newLabel)
  }

  isUsingTag(color, label, notesMap = {}) {
    return !!(
      this.tags.some(
        (tag) =>
          tag.color.trim() === color.trim() &&
          tag.label.trim() === label.trim(),
      ) ||
      this.noteIds.some((noteId) => notesMap[noteId]?.isUsingTag(color, label))
    )
  }

  isUsingAnyTag(tags, notesMap = {}) {
    return (
      tags.some((tag) => this.isUsingTag(tag.color, tag.label)) ||
      this.noteIds.some((noteId) => notesMap[noteId]?.isUsingAnyTag(tags))
    )
  }

  hasAnyTags(notesMap = {}) {
    return (
      this.tags.length > 0 ||
      this.noteIds.some((noteId) => notesMap[noteId]?.tags.length > 0)
    )
  }

  updateTag(
    oldColor,
    oldLabel,
    newColor = null,
    newLabel = null,
    notesMap = {},
  ) {
    let changed = this.tagArray.updateTag(
      oldColor,
      oldLabel,
      newColor,
      newLabel,
    )
    for (const noteId of this.noteIds) {
      const note = notesMap[noteId]
      if (note) {
        const changedNote = note.updateTag(
          oldColor,
          oldLabel,
          newColor,
          newLabel,
        )
        if (changedNote) {
          changed = true
        }
      }
    }
    return changed
  }
  updateTagGloballyPreservingIndex(index, newColor = null, newLabel = null) {
    return this.tagArray.updateTagGloballyPreservingIndex(
      index,
      newColor,
      newLabel,
    )
  }

  deleteTag(color, label, notesMap = {}) {
    let changed = this.tagArray.deleteTag(color, label)
    for (const noteId of this.noteIds) {
      const note = notesMap[noteId]
      if (note) {
        const changedNote = note.deleteTag(color, label)
        if (changedNote) {
          changed = true
        }
      }
    }
    return changed
  }
  deleteTagByIndex(index, notesMap = {}) {
    let changed = this.tagArray.deleteTagByIndex(index)
    for (const noteId of this.noteIds) {
      const note = notesMap[noteId]
      if (note) {
        const changedNote = note.deleteTag(color, label)
        if (changedNote) {
          changed = true
        }
      }
    }
    return changed
  }

  deleteNoteId(noteId) {
    const index = this.noteIds.indexOf(noteId)
    if (index === -1) {
      return false
    }
    this.yMap.get('noteIds').delete(index, 1)
    if (this.annotationNoteId === noteId) {
      this.yMap.set('annotationNoteId', null)
    }
    return true
  }

  addTag(color, label, index) {
    this.tagArray.addTag(color, label, index)
  }

  isEmpty(notesMap = {}) {
    return (
      this.tags.length === 0 &&
      this.noteIds.every(
        (noteId) => !notesMap[noteId] || notesMap[noteId]?.isEmpty(),
      )
    )
  }

  getNonImageFileIds(notesMap = {}) {
    const fileIds = new Set()
    for (const noteId of this.noteIds) {
      const note = notesMap[noteId]
      if (!note) {
        console.warn(`Note with id ${noteId} not found in notesMap`)
        continue
      }
      for (const fileId of note.fileIds) {
        fileIds.add(fileId)
      }
    }
    return fileIds
  }

  getAllBucketFileIds(notesMap = {}) {
    const fileIds = new Set()
    for (const noteId of this.noteIds) {
      const note = notesMap[noteId]
      if (!note) {
        console.warn(`Note with id ${noteId} not found in notesMap`)
        continue
      }
      const fileIds = note.getAllBucketFileIds()
      for (const fileId of fileIds) {
        fileIds.add(fileId)
      }
    }
    return fileIds
  }

  clean(notesMap) {
    const newNotes = []
    for (let i = 0; i < this.noteIds.length; i++) {
      let noteId = this.noteIds[i]
      let note = notesMap[noteId]
      if (note && !note.isEmpty()) {
        newNotes.push(noteId)
      }
    }
    this.noteIds = newNotes
    if (this.annotationNoteId && !notesMap[this.annotationNoteId]) {
      this.annotationNoteId = null
    }
  }

  createTagFrequencyMap(notesMap = {}, result = {}) {
    this.tagArray.createTagFrequencyMap(result)
    const noteIds = this.noteIds.filter((noteId) => !!notesMap[noteId])
    for (const noteId of noteIds) {
      const note = notesMap[noteId]
      if (note) {
        note.createTagFrequencyMap(result)
      }
    }
    return result
  }

  countTags(notesMap = {}) {
    let count = this.tags.length
    for (const noteId of this.noteIds) {
      const note = notesMap[noteId]
      if (note) {
        count += note.tags.length
      }
    }
    return count
  }
  addNoteId(noteId) {
    const noteIds = this.yMap.get('noteIds')

    let existingIndex = noteIds.toJSON().indexOf(noteId)
    while (existingIndex !== -1) {
      noteIds.delete(existingIndex, 1)
      existingIndex = noteIds.toJSON().indexOf(noteId)
    }
    this.yMap.get('noteIds').push([noteId])
  }

  hasAnyNotes() {
    return this.noteIds.length > 0
  }

  hasSomeNoteWithContent(notesMap = {}) {
    return this.noteIds.some((noteId) => notesMap[noteId]?.hasContent())
  }
  hasSomeNoteWithTags(notesMap = {}) {
    return this.noteIds.some((noteId) => notesMap[noteId]?.tags.length > 0)
  }
}

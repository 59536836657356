import Vue from 'vue'
import * as Y from 'yjs'
import { Note } from '@learnics/models/src/Note/Note'
import { getNote } from '../../note/getNote'

export const state = {
  notes: {},
  savingNoteIds: new Set(),
}

export const getters = {
  notes: (state) => state.notes,
  savingNoteIds: (state) => state.savingNoteIds,
}

export const mutations = {
  setNote: (state, { compositeId, newVal, oldVal, forced, refresh = true }) => {
    const { id } = compositeId
    if (newVal) {
      if (forced || !state.notes[id]) {
        Vue.set(state.notes, id, newVal)
      } else {
        const note = state.notes[id]
        if (newVal !== note) {
          // No need to actually update the note unless the YDoc has changed.
          Y.applyUpdate(note.yDoc, Y.encodeStateAsUpdate(newVal.yDoc))
        }
        if (refresh) {
          Vue.set(state.notes, id, Note.shallowCopy(note))
        }
      }
    } else Vue.delete(state.notes, id)
  },
  setSavingNoteIds: (state, { compositeId, newVal, oldVal }) => {
    Vue.set(state, 'savingNoteIds', new Set(newVal || []))
  },
  addSavingNoteId: (state, id) => {
    state.savingNoteIds = new Set([...state.savingNoteIds]).add(id)
  },
  removeSavingNoteId: (state, id) => {
    state.savingNoteIds = new Set(
      [...state.savingNoteIds].filter((x) => x !== id),
    )
  },
}

export const actions = {
  // Refresh the note by replacing it with the current value in the store,
  // which will kick vue change detection into gear.
  refreshNote: ({ commit, state, rootState }, { id }) => {
    commit('setNote', {
      compositeId: { id },
      newVal: state.notes[id],
      refresh: true,
    })
  },

  /**
   * Load one or more Notes into the store.
   *
   * @param state
   * @param assignment
   */
  async loadNotes(
    { state, dispatch, commit, rootState },
    args = { forced: false },
  ) {
    const ids = args.ids || []

    console.log('loadNotes: ', ids)
    for (const id of ids) {
      await dispatch('loadNote', {
        id,
        forced: args.forced,
      })
      // If we have the Note already, don't load it again
    }
  },

  /**
   * Load a Note into the store.
   *
   * @param state
   * @param assignment
   */

  async loadNote({ state, commit, rootState }, args = { forced: false }) {
    const { id } = args
    const existingNote = state.notes[id]
    if (!existingNote || args.forced) {
      const note = await getNote(id)
      if (note) {
        commit('setNote', {
          compositeId: { id },
          newVal: note,
          forced: args.forced,
        })
      }
    }
  },
}

import { nanoid } from 'nanoid'
import { generateJSON } from '@tiptap/html'
import { ResearchNotebook } from '../../ResearchNotebook/ResearchNotebook'
import { WebsiteSource } from '../../ResearchNotebook/WebsiteSource'
import { LoggerSession } from '../LoggerSession'
import { loggerSessionFromJson } from '../../Organization/Course/Assignment/Submission/loggerSessionFromJson'
import { OldLoggerSession } from '../../Organization/Course/Assignment/Submission/OldLoggerSession'
import { activeTipTapExtensions } from '../../utils/tiptap/activeTipTapExtensions'
import { paths } from '../../utils/paths'
import { yjsInstance } from '../../utils/yjsInstance'
import { Note } from '../../Note/Note'
import { BasicAccessList } from '../../BasicAccessList/BasicAccessList'
import { getRevealedSites } from '../../Organization'

/**
 * Upgrade an old LoggerSession to the new format.  This does NOT save the new
 * LoggerSession to the database.  That is left up to the caller.
 *
 * @param session some OldLoggerSession object
 * @param dataLoaders an object with functions to load data from the database (or as the caller sees fit)
 * @return {Promise<{notes: {}, researchNotebook: ResearchNotebook, session: LoggerSession, selectedNoteIds: {}}|{notes: {}, researchNotebook: ResearchNotebook, acls: {}, session: null|OldAssignmentLoggerSession|OldOneClickLoggerSession|AssignmentLoggerSession|OneClickLoggerSession, selectedNoteIds: {}}|null>}
 */

export async function upgradeOldSession(
  session,
  userId,
  dataLoaders = {
    getResearchNotebook: async (id) => new ResearchNotebook({}),
    getNote: async (id) => new Note({}),
    getUserSiteDatas: async (id) => ({}),
    loadAllSecrets: async (id) => ({}),
    loadRunningCalculators: async (id) => ({}),
  },
) {
  if (session instanceof LoggerSession) {
    const researchNotebook = await dataLoaders.getResearchNotebook(
      session.researchNotebookId,
    )
    const notes = {}
    const noteIds = researchNotebook.getNoteIds()
    for (const noteId of noteIds) {
      notes[noteId] = await dataLoaders.getNote(noteId)
    }
    return {
      session,
      selectedNoteIds: {},
      notes,
      researchNotebook,
    }
  } else if (session instanceof OldLoggerSession) {
    return await upgrade(session, userId, dataLoaders)
  } else {
    console.warn(
      `Failed to upgrade LoggerSession ${session.id}: unrecognized session type`,
      session,
    )
    return null
  }
}

async function upgrade(
  oldSession,
  userId,
  dataLoaders = {
    getUserSiteDatas: async (id) => ({}),
    loadAllSecrets: async (id) => ({}),
    loadRunningCalculators: async (id) => ({}),
  },
) {
  const Y = yjsInstance.Y
  const researchNotebookId = nanoid(32)
  const opts = {
    id: oldSession.id,
    name: oldSession.name,
    type: oldSession.type,
    userId: oldSession.userId,
    dateCreated: oldSession.dateCreated,
    assignmentId: oldSession.assignmentId,
    version: '0.0.1',
    researchNotebookId: researchNotebookId,
    tags: oldSession.tags, // Kept for compatibility with OldLoggerSession, just in case.  Can be removed later.
  }

  const userSiteDatas = await dataLoaders.getUserSiteDatas(oldSession.id)
  const siteDataIds = Object.keys(userSiteDatas)
  const researchNotebook = new ResearchNotebook({
    id: researchNotebookId,
    sessionId: oldSession.id,
  })

  const sourcesMap = researchNotebook.yDoc.getMap('sources')
  const selectedNoteIds = {}

  const secrets = await dataLoaders.loadAllSecrets(oldSession.id)
  const runningCalculators = await dataLoaders.loadRunningCalculators(
    oldSession.id,
  )
  const allNotes = {}
  for (const siteDataId of siteDataIds) {
    const userSiteData = userSiteDatas[siteDataId]
    const id = nanoid(32)
    const hashedUrl = userSiteData.id

    const site = getRevealedSites(runningCalculators, secrets, [hashedUrl])[0]
    if (!site) {
      console.warn(`No site found for hashed URL ${hashedUrl}`)
      continue
    }

    let origNoteIds = userSiteData.notes.map((note) => note.id)
    let newNoteIds = origNoteIds.map((id) => nanoid(32))
    const noteIds = new Y.Array()
    noteIds.push(newNoteIds)

    sourcesMap.set(id, new Y.Map())
    const source = sourcesMap.get(id)
    source.set('id', id)
    source.set('type', WebsiteSource.TYPE)
    source.set('url', site.url)
    source.set('title', site.title)
    source.set('noteIds', noteIds)
    source.set('dateAccessed', site.firstFocus)
    let annotationNoteIdIndex = origNoteIds.indexOf(userSiteData.annotationId)
    let annotationNoteId = null
    if (annotationNoteIdIndex >= 0) {
      annotationNoteId = newNoteIds[annotationNoteIdIndex]
    }
    source.set('annotationNoteId', annotationNoteId)
    // Give the map of selected note IDs back to the caller, because it
    // shouldn't be stored in the source object.  It can be stored
    // elsewhere.
    let editingNoteIdIndex = origNoteIds.indexOf(userSiteData.editingNoteId)
    if (editingNoteIdIndex < 0 && newNoteIds.length > 0) {
      editingNoteIdIndex = 0
    }
    if (editingNoteIdIndex >= 0) {
      selectedNoteIds[id] = newNoteIds[editingNoteIdIndex]
    }

    const tagsArray = new Y.Array()
    source.set('tags', tagsArray)
    tagsArray.push(createTagArray(userSiteData.tagIds, oldSession.tags))

    const tiptapTransformer = yjsInstance.tiptapTransformer
    for (let i = 0; i < userSiteData.notes.length; i++) {
      const note = userSiteData.notes[i]
      const newId = newNoteIds[i]
      const json = generateJSON(note.text, activeTipTapExtensions.get())
      const newNote = new Note({ id: newId })
      newNote.yDoc = tiptapTransformer.toYdoc(json, 'content', [
        ...activeTipTapExtensions.get(),
      ])
      newNote.yDoc.getArray('fileIds').push(note.fileIds)

      newNote.yDoc
        .getArray('tags')
        .push(createTagArray(note.tagIds, oldSession.tags))
      allNotes[newId] = newNote
    }
  }

  const acls = {}

  const newSession = loggerSessionFromJson(opts)

  const sessionOwnerIds = oldSession.userId ? [oldSession.userId] : []
  const assignmentIds = oldSession.assignmentId ? [oldSession.assignmentId] : []
  acls[paths.loggerSession(oldSession.id)] = new BasicAccessList({
    roles: {
      Owner: sessionOwnerIds,
    },
    parents: {
      assignment: assignmentIds,
    },
  })
  const noteIds = Object.keys(allNotes)
  for (const noteId of noteIds) {
    acls[paths.note(noteId)] = new BasicAccessList({
      roles: {
        Owner: [userId],
      },
      parents: {
        researchNotebook: [researchNotebook.id],
      },
    })
  }
  acls[paths.researchNotebook(researchNotebook.id)] = new BasicAccessList({
    roles: {
      Owner: [userId],
    },
  })

  return {
    session: newSession,
    selectedNoteIds,
    researchNotebook,
    notes: allNotes,
    acls,
    userSiteData: userSiteDatas,
    oldSession: oldSession,
  }
}

function createTagArray(tagIds, tagMap) {
  const Y = yjsInstance.Y
  tagIds = tagIds.filter(
    (tagId) =>
      !!tagMap[tagId] && !!tagMap[tagId].label?.trim() && !!tagMap[tagId].color,
  )
  const uniqueMap = {}
  for (const tagId of tagIds) {
    uniqueMap[tagMap[tagId].color] ||= new Set()
    uniqueMap[tagMap[tagId].color].add(tagMap[tagId].label.trim())
  }
  const result = []
  Object.keys(uniqueMap).forEach((color) => {
    for (const label of uniqueMap[color]) {
      const m = new Y.Map()
      m.set('id', nanoid()) // IDs don't matter here, but they ARE required
      m.set('color', color)
      m.set('label', label)
      result.push(m)
    }
  })
  return result
}
